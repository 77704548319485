import React, { ChangeEvent } from 'react'
import { BaseInput, CommonInputDefaultProps, CommonInputProps } from './components/BaseInput'

export interface Props extends Omit<CommonInputProps, 'endAdornment' | 'large' | 'startAdornment'> {
  /** Maximum number of rows to display. */
  maxRows?: number
  /** Minimum number of rows to display. */
  minRows?: number
  /** Callback fired when `input` value changes. */
  onChange?: (event: ChangeEvent) => void
  /** The value of the `input` element, required for a controlled component. */
  value?: string
}

/**
 * A long text input.
 */
export function TextArea({
  autoComplete,
  autoFocus,
  disabled,
  error,
  fullWidth,
  gutterBottom,
  helperText,
  label,
  maxRows,
  minRows = 4,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  required,
  value
}: Props) {
  return (
    <BaseInput
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      gutterBottom={gutterBottom}
      helperText={helperText}
      label={label}
      maxRows={maxRows || minRows}
      minRows={minRows}
      multiline
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      placeholder={placeholder}
      required={required}
      value={value}
    />
  )
}

TextArea.defaultProps = CommonInputDefaultProps
