import { defaults } from 'react-chartjs-2'
import { cyan, green, grey, orange, pink, purple, red, yellow } from '@material-ui/core/colors'
import { createTheme as createMuiTheme, alpha as fade, lighten } from '@material-ui/core/styles'
import { light as defaultPalette } from '@material-ui/core/styles/createPalette'
import Colors from './Colors'

export const cacheStatusColors = {}

const lightCacheStatusColors = {
  red: red['A700'],
  lightRed: lighten(red['A700'], 0.5),
  yellow: yellow[600],
  yellowText: '#e90',
  lightYellow: lighten(yellow[600], 0.45),
  green: green[400],
  lightGreen: lighten(green[400], 0.5),
  gray: grey[300]
}

const darkCacheStatusColors = {
  red: red['500'],
  lightRed: lighten(red['500'], 0.5),
  yellow: yellow[600],
  yellowText: '#e90',
  lightYellow: lighten(yellow[600], 0.45),
  green: green[400],
  lightGreen: lighten(green[400], 0.5),
  gray: grey[300]
}

export const webVitalsColors = {
  good: green[500],
  fair: orange[400],
  poor: red[500]
}

export const backgroundColor = (dark) => {
  return dark ? Colors.EerieBlack : Colors.White
}

export default function createTheme(dark) {
  Object.assign(cacheStatusColors, dark ? darkCacheStatusColors : lightCacheStatusColors)

  const primary = {
    main: Colors.EdgioBlue,
    light: dark ? pink['A100'] : pink[400],
    dark: Colors.EdgioBlue,
    contrastText: Colors.White
  }

  const theme = createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1600
      }
    },
    palette: {
      type: dark ? 'dark' : 'light',
      brand: {
        primary: grey[800],
        secondary: '#DD549F',
        contrastText: grey[50]
      },
      common: {
        black: Colors.Jet,
        white: Colors.White
      },
      primary,
      secondary: {
        main: Colors.SilverSand,
        light: purple[400],
        dark: Colors.BleuDeFrance,
        contrastText: Colors.White
      },
      danger: {
        main: red['A400'],
        light: red[300],
        dark: red[700],
        contrastText: '#fff'
      },
      error: {
        main: Colors.PastelRed
      },
      success: primary,
      warning: {
        main: yellow[500],
        light: yellow[300],
        dark: yellow[700],
        contrastText: '#fff'
      },
      text: {
        primary: dark ? Colors.White : Colors.EerieBlack,
        secondary: dark ? Colors.White : Colors.GraniteGray,
        disabled: Colors.SilverSand
      },
      background: {
        paper: dark ? Colors.Jet : Colors.White
      },
      borderColor: {
        main: dark ? Colors.GraniteGray : Colors.SilverSand
      },
      action: {
        active: dark ? Colors.White : Colors.EerieBlack,
        disabled: Colors.SilverSand,
        disabledBackground: dark ? Colors.GraniteGray : Colors.Cultured,
        hover: fade(primary.main, 0.05),
        selected: fade(primary.main, 0.1)
      },
      cacheStatus: cacheStatusColors,
      webVitals: webVitalsColors,
      codeBackground: {
        dark: {
          shady: fade(grey[700], 0.2)
        },
        danger: {
          dark: red[900],
          shady: fade(red[900], 0.7)
        },
        success: {
          dark: green[900]
        },
        warning: {
          dark: orange[900]
        },
        link: {
          highlight: fade(cyan[700], 0.2)
        }
      }
    },
    typography: {
      fontFamily: '"Inter", sans-serif',
      h1: {
        color: dark ? Colors.White : Colors.EerieBlack,
        fontSize: '1.75rem',
        lineHeight: 1.2,
        margin: '0.5rem 0 2rem'
      },
      h2: {
        fontSize: '2rem',
        margin: '15px 0 15px'
      },
      h3: {
        fontSize: '1.75rem'
      },
      h4: {
        fontSize: '1.25rem'
      },
      subtitle1: {
        color: dark ? Colors.White : Colors.GraniteGray,
        fontSize: '1.25rem',
        lineHeight: 1.2
      },
      body1: {
        color: dark ? Colors.White : Colors.EerieBlack
      },
      body2: {
        color: dark ? Colors.White : Colors.EerieBlack,
        fontSize: '0.875rem'
      },
      button: {
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: 1.2,
        textTransform: 'none'
      },
      caption: {
        color: dark ? Colors.White : Colors.GraniteGray,
        lineHeight: 1.33
      },
      captionContrast: {
        color: Colors.White,
        lineHeight: 1.33
      },
      code: {
        fontFamily: "Menlo, Monaco, 'Courier New', monospace", // Here we use the font from VS Code because it's the most popular editor.  One day this comment will be horribly outdated.
        fontSize: 12,
        backgroundColor: dark ? 'rgb(29, 31, 33)' : grey[800]
      }
    },
    shape: {
      borderRadius: 3
    },
    overrides: {}
  })

  Object.assign(theme.overrides, {
    MuiCssBaseline: {
      '@global': {
        a: {
          color: dark ? cyan['A200'] : cyan[700],
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline'
          }
        },
        '#root': {
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: backgroundColor(dark)
        },
        '#root .App': {
          flex: 1,
          display: 'flex',
          flexDirection: 'column'
        },
        '#root .App main': {
          flex: 1,
          flexDirection: 'column',
          '& code': {
            color: theme.palette.text.primary,
            padding: '3px 5px',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.palette.divider,
            whiteSpace: 'pre',
            ...theme.typography.code
          }
        },
        [`input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus`]: {
          ...theme.typography.body1,
          border: 'none',
          borderRadius: '0 !important',
          '-webkit-box-shadow': `0 0 0px 1000px ${theme.palette.background.paper} inset !important`
        },
        '.statusGoodText': {
          color: `${theme.palette.cacheStatus.green} !important`
        },
        '.statusFairText': { color: `${theme.palette.cacheStatus.yellowText} !important` },
        '.statusPoorText': { color: `${theme.palette.cacheStatus.red} !important` }
      }
    },
    MuiToolbar: {
      gutters: {
        paddingLeft: 0,
        paddingRight: 0,
        [theme.breakpoints.up('sm')]: {
          paddingLeft: 0,
          paddingRight: 0
        }
      }
    },
    MuiCardActions: {
      root: {
        padding: theme.spacing(2, 0, 0, 0)
      }
    },
    MuiCardHeader: {
      root: {
        paddingBottom: 0
      }
    },
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: theme.spacing(2)
        }
      }
    },
    MuiTableHead: {
      root: {
        backgroundColor: dark ? grey[700] : grey[100],
        border: `1px solid ${defaultPalette.divider}`
      }
    },
    MuiDialogActions: {
      root: {
        paddingTop: theme.spacing(3)
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 14
      }
    },
    MuiMenuItem: {
      root: {
        minWidth: 90
      }
    },
    MuiSnackbarContent: {
      message: {
        color: 'white'
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.2)'
      }
    }
  })

  defaults.global.defaultFontColor = dark ? theme.palette.primary.contrastText : grey[800]

  return theme
}
