import React from 'react'
import { Box, Typography as MuiTypography } from '@material-ui/core'

interface Props {
  /** The content of the component. */
  children: JSX.Element | String
  /** The color of the component. It supports those theme colors that make sense for this component. */
  color?: 'error' | 'initial' | 'textPrimary' | 'textSecondary'
  /** The font style of the text */
  fontStyle?: 'bold' | 'italic' | 'normal'
  /** The font weight of the text */
  fontWeight?: 400 | 500 | 600
  /** If `true`, the text will have a bottom margin. */
  gutterBottom?: boolean
  /** Applies the theme typography styles. */
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline'
    | 'srOnly'
    | 'inherit'
}

export function Typography({
  children,
  color = 'initial',
  fontWeight = 400,
  gutterBottom = false,
  fontStyle = 'normal',
  variant = 'body1'
}: Props) {
  return (
    <MuiTypography color={color} gutterBottom={gutterBottom} variant={variant}>
      <Box component="span" display="inline" fontStyle={fontStyle} fontWeight={fontWeight}>
        {children}
      </Box>
    </MuiTypography>
  )
}
