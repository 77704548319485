import { useCallback, useContext } from 'react'
import { gql, useApolloClient, useMutation } from '@apollo/client'
import AppContext from '../AppContext'
import { USER_VIEW } from '../user/userQueries'

export const currentUserQuery = gql`
  {
    currentUser {
      ...UserView
    }
  }
  ${USER_VIEW}
`

const logoutMutation = gql`
  mutation logout {
    logout
  }
`

export const useLogoutMutation = () => {
  const [mutate, mutationResult] = useMutation(logoutMutation)
  const { onLogout } = useContext(AppContext)

  return [
    async () => {
      const { data } = await mutate({})
      if (data.logout) {
        onLogout()
      }

      return data.logout
    },
    mutationResult
  ]
}
const loginMutation = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      userErrors {
        message
        path
      }
    }
  }
`

export const useLoginMutation = () => {
  const [mutate] = useMutation(loginMutation)

  return async (email, password) => {
    const { data } = await mutate({
      variables: { email, password },
      refetchQueries: [{ query: currentUserQuery }],
      awaitRefetchQueries: true
    })

    return data.login
  }
}

const signupMutation = gql`
  mutation signup($email: String!, $fullname: String!, $password: String!, $source: String) {
    signup(email: $email, fullname: $fullname, password: $password, source: $source) {
      success
      userErrors {
        message
        path
      }
    }
  }
`

export const useSignupMutation = () => {
  const [mutate, mutationResult] = useMutation(signupMutation)

  return [
    async ({ email, fullname, password, source }) => {
      const { data } = await mutate({ variables: { email, fullname, password, source } })
      return data.signup
    },
    mutationResult
  ]
}

const emailConfirmationMutation = gql`
  mutation emailConfirmation($confirmationToken: String!) {
    emailConfirmation(confirmationToken: $confirmationToken)
  }
`

export const useEmailConfirmationMutation = () => {
  const [mutate, mutationResult] = useMutation(emailConfirmationMutation)

  return [
    async (confirmationToken) => {
      const { data } = await mutate({
        variables: { confirmationToken },
        // If it works, the user may now be logged in
        refetchQueries: [{ query: currentUserQuery }]
      })
      return data.emailConfirmation
    },
    mutationResult
  ]
}

const resetPasswordRequestMutation = gql`
  mutation resetPasswordRequest($email: String!) {
    resetPasswordRequest(email: $email)
  }
`

const resendConfirmationMutatuon = gql`
  mutation resendConfirmation($email: String!) {
    resendConfirmation(email: $email)
  }
`

export const useResetPasswordRequestMutation = () => {
  const [mutate, mutationResult] = useMutation(resetPasswordRequestMutation)

  return [
    async (email) => {
      const { data } = await mutate({ variables: { email } })
      return data.resetPasswordRequest
    },
    mutationResult
  ]
}

export const useResendConfirmationMutation = () => {
  const [mutate, mutationResult] = useMutation(resendConfirmationMutatuon)

  return [
    async (email) => {
      const { data } = await mutate({ variables: { email } })
      return data.resendConfirmation
    },
    mutationResult
  ]
}

const resetPasswordMutation = gql`
  mutation resetPassword($password: String!, $resetPasswordToken: String!) {
    resetPassword(password: $password, resetPasswordToken: $resetPasswordToken)
  }
`

const changePasswordMutation = gql`
  mutation changePassword($userId: String!, $password: String!) {
    changePassword(userId: $userId, password: $password) {
      user {
        ...UserView
      }
      userErrors {
        message
        path
      }
    }
  }
  ${USER_VIEW}
`

export const useResetPasswordMutation = () => {
  const [mutate, mutationResult] = useMutation(resetPasswordMutation)

  return [
    async (resetPasswordToken, password) => {
      const { data } = await mutate({ variables: { resetPasswordToken, password } })
      return data.resetPassword
    },
    mutationResult
  ]
}

export const useChangePassword = () => {
  const [mutate, mutationResult] = useMutation(changePasswordMutation)

  return [
    async (userId, password) => {
      const { data } = await mutate({ variables: { userId, password } })
      return data.changePassword
    },
    mutationResult
  ]
}

const unlockAccountMutation = gql`
  mutation unlockAccount($token: String!) {
    unlockAccount(token: $token)
  }
`

export const useUnlockAccountMutation = () => {
  const [mutate, mutationResult] = useMutation(unlockAccountMutation)

  return [
    async (token) => {
      const { data } = await mutate({ variables: { token } })
      return data.unlockAccount
    },
    mutationResult
  ]
}

const SamlIdentityProviderUrlByTeam = gql`
  query samlIdentityProviderByTeamSlug($teamSlug: String!) {
    url: samlIdentityProviderByTeamSlug(teamSlug: $teamSlug)
  }
`

export const useGetSamlSSOUrlByTeamSlug = () => {
  const client = useApolloClient()

  return useCallback(
    (variables) =>
      client.query({
        query: SamlIdentityProviderUrlByTeam,
        variables: variables
      }),
    [client]
  )
}
