import { useMemo } from 'react'
import {
  BaseSelect,
  CommonSelectDefaultProps,
  CommonSelectProps,
  OptionType
} from './components/BaseSelect'
import { Chip } from './components/Chip'

export interface Props<T> extends CommonSelectProps<T> {
  /** Used to determine if an option is selected, considering the current value. Uses strict equality by default. */
  getOptionSelected?: (option: T, value?: (Partial<T> | string)[]) => boolean
  /** Callback fired when the value changes. */
  onChange?: (selectedValue: T[]) => void
  /**
   * The value of the autocomplete.
   *
   * The value must have reference equality with the option in order to be selected. You can customize the equality
   * behavior with the `getOptionSelected` prop.
   */
  value?: (Partial<T> | string)[]
}

/**
 * A multiple select component.
 */
export function MultiSelect<T extends OptionType>({
  clearable,
  createCustomOption,
  disabled,
  error,
  fullWidth,
  getOptionDisabled = (option) => option.disabled,
  getOptionLabel = (option) => option.name,
  groupBy,
  gutterBottom,
  helperText,
  label,
  large,
  name,
  onBlur,
  onChange,
  onFocus,
  options,
  placeholder,
  required,
  renderOption,
  value
}: Props<T>) {
  const selectedValues = useMemo(() => {
    if (value) {
      return options.filter((option) => value.indexOf(option.value) !== -1)
    }
    return []
  }, [options, value])

  return (
    <BaseSelect
      clearable={clearable}
      createCustomOption={createCustomOption}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      getOptionDisabled={getOptionDisabled}
      getOptionLabel={getOptionLabel}
      getOptionSelected={(option) => {
        return selectedValues.some((selectedValue) => option.value === selectedValue.value)
      }}
      groupBy={groupBy}
      gutterBottom={gutterBottom}
      helperText={helperText}
      label={label}
      large={large}
      multiple
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      options={options}
      placeholder={placeholder}
      required={required}
      renderOption={renderOption}
      renderTags={(values, getTagProps) =>
        values.map((option, index) => {
          return (
            <Chip
              key={getOptionLabel(option)}
              label={getOptionLabel(option)}
              large={large}
              // @ts-ignore Typed incorrectly in Material-UI
              tagProps={getTagProps({ index })}
            />
          )
        })
      }
      selectedValue={selectedValues}
    />
  )
}

MultiSelect.defaultProps = CommonSelectDefaultProps
