import React from 'react'
import { FormControlLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(-1)
  },
  label: {
    '.danger &': {
      color: theme.palette.error.main
    }
  }
}))

interface Props {
  control: JSX.Element
  danger?: boolean
  label?: string
}

export function ControlLabel({ control, danger = false, label }: Props) {
  const classes = useStyles()

  return (
    <FormControlLabel
      classes={classes}
      className={danger ? 'danger' : ''}
      control={control}
      label={label}
    />
  )
}
