import React, { ChangeEvent, Ref, useState } from 'react'
import { FormHelperText, OutlinedInput } from '@material-ui/core'
import { alpha, makeStyles } from '@material-ui/core/styles'
import { Adornment } from './Adornment'
import { CommonControlDefaultProps, CommonControlProps } from './BaseControl'
import { InputLabel } from './InputLabel'

// @ts-ignore
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.common.black : theme.palette.common.white,
    '&$focused $notchedOutline': {
      border: `1px solid ${alpha(theme.palette.primary.main, 0.4)}`,
      boxShadow: `0px 0px 0.3125rem ${theme.palette.primary.main}`
    },
    // @ts-ignore
    flexWrap: (props) => props?.flexWrap && 'wrap'
  },
  adornedStart: {
    paddingLeft: theme.spacing(1)
  },
  adornedEnd: {
    paddingRight: theme.spacing(1)
  },
  disabled: {
    backgroundColor: theme.palette.action.disabledBackground
  },
  input: {
    paddingTop: theme.spacing(1.3125),
    paddingBottom: theme.spacing(1.3125),
    '$marginDense &': {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5)
    },
    '&::placeholder': {
      color: theme.palette.action.disabled,
      fontWeight: 500,
      opacity: 1
    },
    '&:-webkit-autofill': {
      '&, &:hover': {
        '-webkit-box-shadow': `0 0 0px 1000px ${
          theme.palette.type === 'dark' ? theme.palette.common.black : theme.palette.common.white
        } inset !important`
      }
    }
  },
  notchedOutline: {
    borderColor: theme.palette.action.disabled,
    transition: 'ease-in 300ms',
    transitionProperty: 'border, box-shadow'
  },
  focused: {},
  marginDense: {}
}))

export interface CommonInputProps extends CommonControlProps {
  /**
   * This prop helps users to fill forms faster, especially on mobile devices. You can learn more about it
   * <a href="https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill" target="_blank">following the specification</a>.
   */
  autoComplete?: string
  /** If `true`, the `input` element will be focused during the first mount. */
  autoFocus?: boolean
  /** End `InputAdornment` for this component. */
  endAdornment?: JSX.Element
  /** If `true`, input will be large. */
  large?: boolean
  /** Name attribute of the `input` element. */
  name: string
  /** The short hint displayed in the input before the user enters a value. */
  placeholder?: string
  /** Start `InputAdornment` for this component. */
  startAdornment?: JSX.Element
}

export const CommonInputDefaultProps = {
  ...CommonControlDefaultProps,
  autoFocus: false,
  large: false
}

interface Props extends CommonInputProps {
  inputProps?: object
  InputProps?: object
  inputRef?: Ref<HTMLInputElement>
  labelRequired?: boolean
  max?: number
  min?: number
  maxRows?: number
  minRows?: number
  multiline?: boolean
  onChange?: (event: ChangeEvent) => void
  type?: string
  value?: string | number
  flexWrap?: boolean
}

export function BaseInput({
  autoComplete,
  autoFocus,
  disabled,
  endAdornment,
  error,
  fullWidth,
  gutterBottom,
  helperText,
  inputProps,
  InputProps,
  inputRef,
  label,
  labelRequired,
  large,
  max,
  min,
  maxRows,
  minRows,
  multiline,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  required,
  startAdornment,
  type,
  value,
  flexWrap
}: Props) {
  const classes = useStyles({ flexWrap })
  const [isFocused, setIsFocused] = useState(false)

  return (
    <div>
      {label && (
        <InputLabel id={name} required={required || labelRequired}>
          {label}
        </InputLabel>
      )}
      <OutlinedInput
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        classes={classes}
        disabled={disabled}
        endAdornment={
          endAdornment && (
            <Adornment focused={isFocused} position="end">
              {endAdornment}
            </Adornment>
          )
        }
        error={error}
        fullWidth={fullWidth}
        id={name}
        inputProps={{
          ...inputProps,
          max,
          min
        }}
        inputRef={inputRef}
        margin={large ? 'none' : 'dense'}
        maxRows={maxRows}
        minRows={minRows}
        multiline={multiline}
        notched={false}
        name={name}
        onBlur={(event) => {
          setIsFocused(false)
          onBlur && onBlur(event)
        }}
        onChange={onChange}
        onFocus={(event) => {
          setIsFocused(true)
          onFocus && onFocus(event)
        }}
        placeholder={placeholder}
        required={required}
        startAdornment={
          startAdornment && (
            <Adornment focused={isFocused} position="start">
              {startAdornment}
            </Adornment>
          )
        }
        type={type}
        value={value}
        {...InputProps}
      />
      {(helperText || gutterBottom) && (
        <FormHelperText error={error}>{helperText || (gutterBottom && ' ')}</FormHelperText>
      )}
    </div>
  )
}

BaseInput.defaultProps = CommonInputDefaultProps
