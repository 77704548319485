import { addDays, addMilliseconds, isAfter, isBefore, subMilliseconds } from 'date-fns'
import { groupBy, last } from 'lodash'

type GroupAndFillMissingDataProps = {
  data: Array<NonParsedDataPoint>
  groupByProp: string
  duration: string
  fillWithUndefined: boolean
  intervalMap?: IntervalMap
}

type IntervalMap = {
  [n: string]: number
}

type FillMissingDataPointsProps = {
  data: Array<NonParsedDataPoint>
  yTransform: (DataPoint) => number
  duration?: string
  interval?: number
  earliestDataTimestamp: number
  latestDataTimestamp: number | Date
  fillWithUndefined?: boolean
  intervalMap?: IntervalMap
}

type NonParsedDataPoint = {
  timestamp: Date
}

type DataPoint = {
  t: Date
  y: number
}

const DEFAULT_INTERVALS = {
  '1d': 1000 * 60 * 10,
  '1w': 1000 * 60 * 60,
  '1m': 1000 * 60 * 60 * 6
}

const NUM_DAYS = {
  '1d': 1,
  '1w': 7,
  '1m': 28
}

type GetStartAndEndProps = {
  data: Array<NonParsedDataPoint>
  duration: string
  interval: number
  intervalMap: IntervalMap
}

/**
 * Determines the right start and end times for a given duration
 */
const getStartAndEnd = ({
  data,
  duration,
  interval,
  intervalMap
}: GetStartAndEndProps): [Date, Date] => {
  const actualInterval = interval || intervalMap[duration]
  let start = new Date(data[0].timestamp)
  let end = new Date(last(data).timestamp)
  const now = new Date()
  const durStart = addDays(now, -NUM_DAYS[duration])
  while (start > addMilliseconds(durStart, actualInterval)) {
    start = addMilliseconds(start, -actualInterval)
  }
  // metrics data goes up until 2*INTERVAL seconds before now()
  while (end < addMilliseconds(now, -actualInterval * 3)) {
    end = addMilliseconds(end, actualInterval)
  }
  return [start, end]
}

const getFillValue = (latestDataDate: Date, dataPointDate: Date): number => {
  return isAfter(dataPointDate, latestDataDate) ? undefined : 0
}

/**
 * Fills the `data` with missing data points so that there are consistent 1-hour
 * intervals through the whole data set.
 * @param data The data to transform
 * @param yTransform A function to convert the item to the `y` value for each point
 * @param duration Duration to use ('1d', '1w', '1m')
 * @param interval Interval to use for the given duration, if different than the INTERVALS map
 * @param earliestDataTimestamp Timestamp of the first true datapoint, to determine the
 *   point from which to fill in empty data points. Defaults to the beginning of the duration.
 *   When there are multiple datasets, this is used to fill in 0s for time points before
 *   the first point in the given dataset
 * @param latestDataTimestamp Timestamp of the last true datapoint, to determine if
 *   empty spaces should be filled with 0 or undefined. Defaults to the last data point in
 *   the given dataset. When there are multiple datasets, this is used to fill in undefined
 *   data points past the last point in the given dataset
 * @param fillWithUndefined Flag to specify if empty data after the last dataPoint will
 *    be filled with undefineds or with zeros
 */
export const fillMissingDataPoints = ({
  data,
  yTransform,
  duration,
  interval,
  earliestDataTimestamp,
  latestDataTimestamp,
  fillWithUndefined = true,
  intervalMap = DEFAULT_INTERVALS
}: FillMissingDataPointsProps): Array<DataPoint> => {
  if (!data || !data.length) {
    return []
  }

  // to select start and end of the timespan we need to
  // compare 3 values (using timestampStart as example):
  //    * earliestDataTimestamp - passed as function argument
  //    * timestamp of first dataPoint
  //    * start of timespan calculated from duration parameter -
  //      i.e. 1 day before now if duration === '1d'
  const [timespanByDurationStart, timespanByDurationEnd] = getStartAndEnd({
    data,
    duration,
    interval,
    intervalMap
  })

  // use earliestDataTimestamp if it's passed, else use earliest timestamp from data
  const earliestDataDate = new Date(earliestDataTimestamp || data[0].timestamp)
  // choose start date of timespan from earliestDataDate or timespanStart
  // whichever is earlier
  const timespanStart = isBefore(earliestDataDate, timespanByDurationStart)
    ? new Date(earliestDataTimestamp || data[0].timestamp)
    : timespanByDurationStart

  // use the same process to determine timespanEnd
  const latestDataDate = new Date(latestDataTimestamp || last(data).timestamp)
  const timespanEnd = isAfter(latestDataDate, timespanByDurationEnd)
    ? latestDataDate
    : timespanByDurationEnd

  const result: DataPoint[] = []
  // use passed interval if present, otherwise use predefined
  const actualInterval = interval || intervalMap[duration]

  let newestCreatedDataPoint = subMilliseconds(timespanStart, 1)
  data.forEach((dataPoint) => {
    const dataPointDate = new Date(dataPoint.timestamp)

    // we need to fill timespan with empty datapoints in case of sparse data
    // i.e. errors data
    const emptyDataPoints: DataPoint[] = []
    let dateToCreate = subMilliseconds(dataPointDate, actualInterval)
    while (isAfter(dateToCreate, newestCreatedDataPoint)) {
      emptyDataPoints.push({
        t: dateToCreate,
        y: 0
      })
      dateToCreate = subMilliseconds(dateToCreate, actualInterval)
    }
    // empty dataPoints are created from newest to oldest so we need to
    // reverse them before appending to result array
    result.push(...emptyDataPoints.reverse())

    // append actual dataPoint data
    result.push({
      t: dataPointDate,
      y: yTransform(dataPoint),
      ...dataPoint
    })

    newestCreatedDataPoint = dataPointDate
  })

  // create emptyDataPoints from latest dataPoint to end of the timespan
  let dateToCreate = addMilliseconds(newestCreatedDataPoint, actualInterval)
  while (isBefore(dateToCreate, timespanEnd)) {
    result.push({
      t: dateToCreate,
      // if we want to fill empty dataPoints after last dataPoint with undefined
      // to assume that data is missing on backend
      y: fillWithUndefined ? getFillValue(latestDataDate, dateToCreate) : 0
    })
    dateToCreate = addMilliseconds(dateToCreate, actualInterval)
  }

  return result
}

export function groupAndFillData({
  data,
  groupByProp,
  duration,
  fillWithUndefined = true,
  intervalMap = DEFAULT_INTERVALS
}: GroupAndFillMissingDataProps): { [key: string]: Array<DataPoint> } {
  const groupedData = groupBy(data, groupByProp)

  const earliestDataTimestamp = Math.min(
    ...Object.values(groupedData).map((data) => new Date(data[0].timestamp).getTime())
  )
  const latestDataTimestamp = Math.max(
    ...Object.values(groupedData).map((data) => new Date(last(data).timestamp).getTime())
  )

  const result = {}
  Object.keys(groupedData).forEach((key) => {
    result[key] = fillMissingDataPoints({
      data: groupedData[key],
      yTransform: (item) => parseInt(item.count),
      earliestDataTimestamp,
      latestDataTimestamp,
      duration,
      fillWithUndefined,
      intervalMap: intervalMap
    })
  })

  return result
}
