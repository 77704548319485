import { ReactElement } from 'react'
import Box from '@material-ui/core/Box'
import MuiLink from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { Typography } from '../../../Typography'
import { ExternalLinkType } from './types'

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(4),
    padding: theme.spacing(2, 4),
    borderRadius: theme.shape.borderRadius,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.borderColor.main,
    backgroundColor: theme.palette.background.paper,
    textDecoration: 'none',

    '&:hover': {
      boxShadow: theme.shadows[4],
      textDecoration: 'none'
    }
  },
  textBox: {
    color: theme.palette.secondary.dark,
    display: 'inline-flex',
    flexDirection: 'column',
    gap: theme.spacing(0.625)
  },
  linkIcon: {
    '& svg': {
      fontSize: '2rem'
    }
  },
  openInNewTabIcon: {
    color: theme.palette.text.secondary,
    fontSize: '1rem'
  }
}))

export const ExternalLink = ({
  href,
  title,
  icon,
  description
}: ExternalLinkType): ReactElement<ExternalLinkType> => {
  const classes = useStyles()

  return (
    <MuiLink
      href={href}
      className={classes.root}
      target="_blank"
      rel="noopener noreferrer"
      underline="none"
      color="textPrimary"
    >
      {icon && (
        <Box component="span" className={classes.linkIcon}>
          {icon}
        </Box>
      )}
      <Box component="span" className={classes.textBox}>
        <Typography variant="h4" fontWeight={500} color="textPrimary">
          {title}
        </Typography>
        {description && (
          <Typography variant="body1" fontWeight={500} color="textSecondary">
            {description}
          </Typography>
        )}
      </Box>
      <Box component="span" display="inline-flex">
        <OpenInNewIcon className={classes.openInNewTabIcon} />
      </Box>
    </MuiLink>
  )
}
