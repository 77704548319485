import React from 'react'
import MuiInputLabel from '@material-ui/core/InputLabel'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    marginBottom: theme.spacing(0.75)
  }
}))

interface Props {
  children: string
  id: string
  required?: boolean
}

export function InputLabel({ children, id, required = false }: Props) {
  const classes = useStyles()

  return (
    <MuiInputLabel classes={classes} id={id} required={required}>
      {children}
    </MuiInputLabel>
  )
}
