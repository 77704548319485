import React, { ChangeEvent } from 'react'
import { BaseInput, CommonInputDefaultProps, CommonInputProps } from './components/BaseInput'

export interface Props extends CommonInputProps {
  /** Maximum value for the `input` element */
  max?: number
  /** Maximum value for the `input` element */
  min?: number
  /** Callback fired when `input` value changes. */
  onChange?: (event: ChangeEvent) => void
  /** The value of the `input` element, required for a controlled component. */
  value?: number
}

/**
 * A number input.
 */
export function NumberField({
  autoComplete,
  autoFocus,
  disabled,
  endAdornment,
  error,
  fullWidth,
  gutterBottom,
  helperText,
  label,
  large,
  max,
  min,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  required,
  startAdornment,
  value
}: Props) {
  return (
    <BaseInput
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      disabled={disabled}
      endAdornment={endAdornment}
      error={error}
      fullWidth={fullWidth}
      gutterBottom={gutterBottom}
      helperText={helperText}
      inputProps={{
        max,
        min
      }}
      label={label}
      large={large}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      placeholder={placeholder}
      required={required}
      startAdornment={startAdornment}
      type="number"
      value={value}
    />
  )
}

NumberField.defaultProps = CommonInputDefaultProps
