import { useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import Head from 'next/head'
import { CssBaseline } from '@material-ui/core'
import { ApolloProvider, useQuery } from '@apollo/client'
import Cookies from 'js-cookie'
import { AppContextProvider } from '../src/AppContext'
import { DarkModeContextProvider } from '../src/DarkModeContext'
import ErrorBoundary from '../src/ErrorBoundary'
import Footer from '../src/Footer'
import LoadingPage from '../src/LoadingPage'
import { QueryNavigationProvider } from '../src/QueryNavigationContext'
import Snackbar from '../src/Snackbar'
import ThemeProvider from '../src/ThemeProvider'
import TopLevelErrorPage from '../src/TopLevelErrorPage'
import { StaffContextProvider } from '../src/admin/StaffContext'
import { currentUserQuery } from '../src/auth/authQueries'
import { useUnauthenticatedRedirect } from '../src/auth/useUnauthenticatedRedirect'
import { createApolloClient } from '../src/util'

// TODO hardcoded due to bug with 0 deploy not evaluation process.env['GTM_CONTAINER_ID']
const GtmIds = {
  development: null,
  sandbox: 'GTM-K647LVF',
  staging: 'GTM-K647LVF',
  production: 'GTM-M752VZC'
}

const ClientSideApp = ({ Component, pageProps, client }) => {
  const { data, loading, refetch: refetchCurrentUser } = useQuery(currentUserQuery, { client })

  const onLogout = () => {
    // It is important that we will remove the current user before flushing the apollogql-s cache on logout
    // Otherwise we will face issues with reading properties since user is logged in and no data is present.
    client.writeQuery({
      query: currentUserQuery,
      data: { currentUser: null }
    })

    client.cache.reset()
    client.resetStore()
  }

  useEffect(() => {
    const gtmId = GtmIds[process.env['ENVIRONMENT_NAME']]

    if (gtmId) {
      TagManager.initialize({ gtmId })
    }
  }, [])

  const currentUser = data && data.currentUser

  useUnauthenticatedRedirect({ currentUser, loading, bypass: Component.bypassAuth })

  if (loading) {
    return <LoadingPage />
  }

  const getLayout = Component.getLayout || ((page) => page)

  return (
    <div id="root">
      <Head>
        <title>Layer0</title>
        <meta name="viewport" content="width=device-width,initial-scale=1" />
      </Head>
      <ApolloProvider client={client}>
        <QueryNavigationProvider>
          <DarkModeContextProvider currentUser={currentUser}>
            <ThemeProvider>
              <Snackbar>
                <AppContextProvider
                  currentUser={currentUser}
                  onLogout={onLogout}
                  refetchCurrentUser={refetchCurrentUser}
                >
                  <StaffContextProvider currentUser={currentUser}>
                    <CssBaseline />
                    <ErrorBoundary message={<TopLevelErrorPage />}>
                      {getLayout(<Component {...pageProps} />)}
                    </ErrorBoundary>
                    {Component.includeFooter && <Footer />}
                  </StaffContextProvider>
                </AppContextProvider>
              </Snackbar>
            </ThemeProvider>
          </DarkModeContextProvider>
        </QueryNavigationProvider>
      </ApolloProvider>
    </div>
  )
}

function App({ Component, pageProps }) {
  const [client, setClient] = useState()

  // redirect to beta (app-next) if opted in
  useEffect(() => {
    if (
      Cookies.get('layer0_internal_beta') === 'true' &&
      window.location.href.indexOf('https://app-next') === -1
    ) {
      window.location.replace(window.location.href.replace('https://app', 'https://app-next'))
    }
  }, [])

  useEffect(() => {
    const setApolloClient = async () => {
      const apolloClient = await createApolloClient()
      setClient(apolloClient)
    }
    setApolloClient()
  }, [])

  if (client) {
    return <ClientSideApp Component={Component} pageProps={pageProps} client={client} />
  } else {
    return <LoadingPage />
  }
}

export default App
