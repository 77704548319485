import { ChangeEvent } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import {
  Autocomplete,
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams
} from '@material-ui/lab'
import { TextField } from '../TextField'
import { CommonInputDefaultProps, CommonInputProps } from './BaseInput'

const useStyles = makeStyles((theme) => ({
  clearIndicator: {
    color: theme.palette.text.secondary
  },
  input: {
    paddingRight: theme.spacing(2),
    '$hasClearIcon &': {
      paddingRight: theme.spacing(6)
    }
  },
  popupIndicator: {
    color: theme.palette.text.secondary,
    height: '1.6875rem',
    marginRight: theme.spacing(0.5)
  },
  hasClearIcon: {}
}))

export type OptionType = {
  [key: string]: any
}

export interface CommonSelectProps<T>
  extends Omit<CommonInputProps, 'autoComplete' | 'autoFocus' | 'endAdornment' | 'startAdornment'> {
  /** If `true`, the input can be cleared. */
  clearable?: boolean
  /** Used to create a custom option from `input` value. */
  createCustomOption?: (event: ChangeEvent) => T
  /** Used to determine the disabled state for a given option. */
  getOptionDisabled?: (option: T) => boolean
  /** Used to determine the string value for a given option. It's used to fill the input. */
  getOptionLabel?: (option: T) => string
  /** If provided, the options will be grouped under the returned string. */
  groupBy?: (option: T) => string
  /**
   * Array of options.
   *
   * **Option signature:**
   * `{ [key: string]: any }`
   */
  options: T[]
  /** Render the option, use `getOptionLabel` by default. */
  renderOption?: (option: T) => JSX.Element
  /** If `true`, text search is enabled. */
  searchable?: boolean
}

export const CommonSelectDefaultProps = {
  ...CommonInputDefaultProps,
  clearable: false,
  searchable: false
}

interface Props<T> extends CommonSelectProps<T> {
  multiple?: boolean
  onChange?: (selectedValue: any) => void
  getOptionSelected: (option: T) => boolean
  renderTags?: (values: T[], getTagProps: AutocompleteGetTagProps) => JSX.Element[]
  selectedValue: T | T[] | null
}

export function BaseSelect<T>({
  clearable,
  createCustomOption,
  disabled,
  error,
  fullWidth,
  getOptionDisabled,
  getOptionLabel,
  getOptionSelected,
  groupBy,
  gutterBottom,
  helperText,
  label,
  large,
  multiple,
  name,
  onBlur,
  onChange,
  onFocus,
  options,
  placeholder,
  renderTags,
  required,
  renderOption,
  searchable,
  selectedValue
}: Props<T>) {
  const classes = useStyles()
  const theme = useTheme()

  const TextFieldComponent = (props: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...props}
        error={error}
        fullWidth={fullWidth}
        gutterBottom={gutterBottom}
        helperText={helperText}
        inputProps={{
          ...props.inputProps,
          ...(!searchable &&
            !disabled && {
              onChange: () => {},
              style: {
                color: 'transparent',
                textShadow:
                  !multiple && selectedValue ? `0 0 0 ${theme.palette.text.primary}` : 'none',
                ...(multiple ? { flexGrow: 1, width: 0, minWidth: '30px' } : {})
              }
            })
        }}
        InputProps={{
          ...props.InputProps,
          className: ''
        }}
        label={label}
        labelRequired={required}
        large={large}
        name={name}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={createCustomOption}
        placeholder={placeholder}
        flexWrap={multiple}
      />
    )
  }

  return (
    <Autocomplete
      blurOnSelect
      classes={classes}
      disableClearable={!clearable}
      disabled={disabled}
      fullWidth={fullWidth}
      getOptionDisabled={getOptionDisabled}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      groupBy={groupBy}
      id={name}
      multiple={multiple}
      onChange={(_event, selectedValue) => {
        onChange && onChange(selectedValue)
      }}
      options={options}
      popupIcon={<KeyboardArrowDownIcon fontSize="small" />}
      renderInput={TextFieldComponent}
      renderOption={renderOption}
      renderTags={renderTags}
      selectOnFocus={!!searchable}
      value={selectedValue}
    />
  )
}

BaseSelect.defaultProps = CommonSelectDefaultProps
