import { Ref, forwardRef } from 'react'
import MuiButton from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '1.6875rem',
    textDecoration: 'none',
    whiteSpace: 'nowrap'
  },
  contained: {
    background: 'linear-gradient(89.92deg, #00BDA6 0%, #00A2E2 132.46%)',
    '&$disabled': {
      background: theme.palette.action.disabled,
      color: theme.palette.common.white
    },
    '&:hover': {
      background: 'linear-gradient(89.77deg, #00A2E2 -6.72%, #01B18D 138.89%)'
    }
  },
  containedSecondary: {
    background: 'linear-gradient(89.86deg, #812991 -1.31%, #00A2E2 138.3%)',
    '&$disabled': {
      background: theme.palette.action.disabled,
      color: theme.palette.common.white
    },
    '&:hover': {
      background: 'linear-gradient(89.86deg, #812991 -1.31%, #00A2E2 138.3%)'
    }
  },
  outlined: {
    backgroundColor: theme.palette.type === 'dark' ? 'transparent' : theme.palette.common.white,
    borderColor: theme.palette.type === 'dark' ? 'white' : '#01B18D',
    color: theme.palette.type === 'dark' ? 'white' : '#01B18D',
    '&$sizeLarge': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '&:not($outlinedPrimary):not($disabled)': {
      color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.common.black
    },
    '&$disabled': {
      // borderColor: 'currentColor',
      border:
        theme.palette.type === 'dark'
          ? `1px solid ${theme.palette.action.disabledBackground}`
          : '1px solid #C4C4C4',
      backgroundColor:
        theme.palette.type === 'dark'
          ? theme.palette.action.disabledBackground
          : 'linear-gradient(0deg, rgba(1, 177, 141, 0.05), rgba(1, 177, 141, 0.05)), #FFFFFF'
    },
    '&:hover': {
      backgroundColor:
        theme.palette.type === 'dark'
          ? theme.palette.action.disabledBackground
          : 'linear-gradient(0deg, rgba(1, 177, 141, 0.05), rgba(1, 177, 141, 0.05)), #FFFFFF',
      border: theme.palette.type === 'dark' ? '1px solid white' : '1px solid #01B18D'
    }
  },
  outlinedSecondary: {
    borderColor: theme.palette.type === 'dark' ? '#01B18D' : '#00A2E2',
    color: '#01B18D',
    '&:not($outlinedPrimary):not($disabled)': {
      color: theme.palette.type === 'dark' ? '#01B18D' : '#00A2E2'
    },
    '&:hover': {
      borderColor: theme.palette.type === 'dark' ? '#01B18D' : '#00A2E2'
    }
  },
  sizeLarge: {
    borderRadius: theme.shape.borderRadius,
    fontSize: theme.typography.button.fontSize,
    height: '2.375rem'
  },
  disabled: {},
  outlinedPrimary: {},
  outlinedDual: {
    borderColor: theme.palette.text.primary,
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor:
        theme.palette.type === 'dark' ? theme.palette.action.disabledBackground : '#F3F3F3',
      borderColor: theme.palette.text.primary
    }
  }
}))

interface CaptchaProps {
  className: string
  'data-sitekey': string
  'data-action': string
  'data-callback': string
}

export interface Props {
  /** Used to pass on props necessary for captcha. */
  captchaProps?: CaptchaProps
  /** The content of the button. */
  children: string | JSX.Element
  /** The color of the component. It supports those theme colors that make sense for this component. */
  color?: 'primary' | 'default' | 'secondary' | 'dual'
  /** If `true`, the button will be disabled. */
  disabled?: boolean
  /** Element placed after the children. */
  endIcon?: JSX.Element
  /** If `true`, button will always be outlined, independent of theme. */
  forceOutlined?: boolean
  /** If `true`, the button will take up the full width of its container. */
  fullWidth?: boolean
  /**
   * @ignore
   * Used only by the `Link` component
   */
  href?: string
  /** If `true`, button will be large */
  large?: boolean
  /** Action that is called on the button click. */
  onClick?: () => void
  /** Element placed before the children. */
  startIcon?: JSX.Element
  /** The type of the button. */
  type?: 'button' | 'submit'
  /** The variant to use. */
  variant?: 'outlined' | 'contained'
}

/**
 * A button implementation that supports all colors in the theme's palette.
 */
export const Button = forwardRef(
  (
    {
      captchaProps,
      children,
      color = 'primary',
      disabled = false,
      endIcon,
      forceOutlined = false,
      fullWidth = false,
      href,
      large,
      onClick,
      startIcon,
      type = 'button',
      variant = 'outlined'
    }: Props,
    ref: Ref<HTMLAnchorElement>
  ) => {
    const classes = useStyles()

    return (
      <MuiButton
        classes={classes}
        // @ts-ignore
        color={color}
        disabled={disabled}
        endIcon={endIcon}
        fullWidth={fullWidth}
        href={href}
        onClick={onClick}
        ref={ref}
        size={large ? 'large' : 'medium'}
        startIcon={startIcon}
        type={type}
        variant={forceOutlined ? 'outlined' : variant}
        {...captchaProps}
      >
        {children}
      </MuiButton>
    )
  }
)

Button.displayName = 'Button'
